import React from 'react';

import Contact from '../components/contact';
import Hero from '../components/hero';
import Layout from '@src/components/layout';

import postrostningPdf from '../../static/pdfs/agm/postrostning.pdf';
import kallelse from '../../static/pdfs/agm/Kallelse.pdf';
import fullmaktsformularPdf from '../../static/pdfs/agm/fullmaktsformular.pdf';
import Optionsvillkor1 from '../../static/pdfs/agm/Optionsvillkor1.pdf';
import Optionsvillkor2 from '../../static/pdfs/agm/Optionsvillkor2.pdf';
import AnnualReport from '../../static/pdfs/agm/AR_2020.pdf';

import * as styles from './agm.module.css';

const AgmPdfs = () => {
  return (
    <Layout newStyle>
      <Hero
        title="Bolagsstämma"
        subtitleMarkdown="Klicka på länkarna nedan för att ladda ner"
      />
      <div className={`container`}>
        <div
          style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <a className={styles.link} href={kallelse} target="blank">
            Kallelse årsstämma 17 maj 2021
          </a>

          <a className={styles.link} href={postrostningPdf} target="blank">
            Formulär för poströstning
          </a>

          <a className={styles.link} href={fullmaktsformularPdf} target="blank">
            Fullmaktsformulär
          </a>

          <a className={styles.link} href={Optionsvillkor1} target="blank">
            Optionsvillkor 2021:1
          </a>
          <a className={styles.link} href={Optionsvillkor2} target="blank">
            Optionsvillkor 2021:2
          </a>
          <a className={styles.link} href={AnnualReport} target="blank">
            Årsredovisning 2020
          </a>
        </div>
      </div>
      <Contact hideFAQ />
    </Layout>
  );
};

export default AgmPdfs;
